import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Bonjour, je suis <span className="purple">Anis Kherraf </span>
            étudiant à <span className="purple"> Aix-Marseille.</span>
            <br />Je suis en 3ème année de <span className="purple"> L3I MIAGE </span>à la
            <span className="purple"> Faculté d'Économie Gestion.</span>
            <br />
            <br />
            Mis à part développer j'ai d'autres passions !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Les Jeux-Vidéos
            </li>
            <li className="about-activity">
              <ImPointRight /> La NBA
            </li>
            <li className="about-activity">
              <ImPointRight /> La Cybersécurité
            </li>
            <li className="about-activity">
              <ImPointRight /> L'automatisation
            </li>
            <li className="about-activity">
              <ImPointRight /> L'Intelligence Artificielle
            </li>
          </ul>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
