import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Laravel from "../../Assets/Projects/laravel.png";
import banque from "../../Assets/Projects/banque.png";
import projet from "../../Assets/Projects/projet.png";
import ionic from "../../Assets/Projects/ionic.png";
import pokedex from "../../Assets/Projects/pokedex.png";
import meteo from "../../Assets/Projects/meteo.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Projets </strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets que j'ai réalisés.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Laravel}
              isBlog={false}
              title="Gestionnaire de Tâches"
              description="Projet de gestion de tâches, avec option d'ajouts, de suppressions de tâches avec possibilité de tri et de recherche par nom fait avec le framework LARAVEL."
              ghLink="https://github.com/1nis/Gestion-Laravel"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ionic}
              isBlog={false}
              title="Ionic Uber / SNCF Alternative"
              description="Création d'une alternative d'UBER avec IONIC qui reprend le même système de course / trajet avec estimation, alternative à l'application SNCF Paris (temps de trajet RER)."
              ghLink="https://github.com/1nis/Ionic-profile"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projet}
              isBlog={false}
              title="Gestion de Stages"
              description="Projet de gestion de stage en groupe dont j'ai été chef de projet. Affichage et gestion de données en PHP / JS / Bootstrap / SQL. Panel entièrement personnalisé avec connexion sécurisée. Barre de recherche à travers toutes les données du projet."
              ghLink="https://github.com/1nis/projet_demede_gestion_stage"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={banque}
              isBlog={false}
              title="TP Banque"
              description="Application en C# de simulation de banque. Gestion des calculs d'ajouts, de retrait, de transfert d'argent avec condition et vérification comme le plafond / frais bancaires."
              ghLink="https://github.com/1nis/TP_Banque_C"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pokedex}
              isBlog={false}
              title="Pokédex des 151 premiers Pokémons"
              description="Projet de Pokédex des 151 premiers Pokémons avec affichage des informations de base de chaque Pokémon. Projet réalisé en PHP / JS / SQL."
              ghLink="https://github.com/1nis/pokedex"
              demoLink="https://kherraf.fr/pokedex/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={meteo}
              isBlog={false}
              title="Application Prévision Météo"
              description="Application de prévision météo en ligne avec API météo. Affichage de la météo actuelle et des prévisions à venir. Projet réalisé en PHP / JS / SQL."
              ghLink="https://github.com/1nis/meteo"
              demoLink="https://kherraf.fr/meteo/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
